<template>
  <div>
    <div class="row">

      <div class="col-xxl-8">
        <b-card>


        <table
          role="table"
          aria-busy="false"
          aria-colcount="7"
          class="table b-table table-striped table-hover"
        >
          <thead role="rowgroup" class="">
            <!---->
            <tr role="row" class="">
              <th
                role="columnheader"
                scope="col"
                tabindex="0"
                aria-colindex="1"
                class=""
              >
                <div>ID</div>                
              </th>
              <th
                role="columnheader"
                scope="col"
                tabindex="0"
                aria-colindex="2"
                class=""
              >
                <div>Requirement</div>
              </th>
              <th
                role="columnheader"
                scope="col"
                tabindex="0"
                aria-colindex="3"
                class=""
              >
                <div>References</div>
              </th>
              <th role="columnheader" scope="col" aria-colindex="4" class="">
                <div>Responsiblity</div>
              </th>
              <th role="columnheader" scope="col" aria-colindex="5" class="">
                <div>Status</div>
              </th>
              <th role="columnheader" scope="col" aria-colindex="6" class="">
                <div>Goal</div>
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-colindex="7"
                aria-label="Calc Status"
                class="statusResponseTd"
              >
                <div></div>
              </th>
            </tr>
          </thead>        

          <tbody role="rowgroup" v-for="item in getFrameworkObjectives(
                getFrameworkValue($route.params.id, 'objective_code')
              )" v-bind:key="item.id">
            <!---->
            <tr role="row" class="">
              <td aria-colindex="1" role="cell" class="">{{item.sub_objective}}</td>
              <td aria-colindex="2" role="cell" class="">
                  {{item.questions}}<br/>
                  <b-button  variant="warning" size="sm" class="actionbuttonworkmanage" @click="addException(item)" v-if="getFrameworkException() == undefined">Mark as NOT Applicable</b-button>
                  <b-button  variant="danger" size="sm" class="actionbuttonworkmanage" @click="addException(item)" v-if="getFrameworkException() != undefined">IS NOT Applicable</b-button>
              </td>
              <td aria-colindex="3" role="cell" class="">{{item.objective_code}}</td>
              <td aria-colindex="4" role="cell" class="">
                <b-form-select
                  v-model="item.responsibility_id"
                  :options="userOptions"
                  @change="frameworkStatusChange(item.responsibility_id, 'responsibility_id', item.id)"></b-form-select>
              </td>
              <td aria-colindex="5" role="cell" class="">
                <b-form-select  v-if="getFrameworkException(item.id) == undefined"
                  v-model="item.status_id"
                  :options="statusOptions"
                  @change="
                    frameworkStatusChange(
                      item.status_id,
                      'status_id',
                      item.id
                    )
                  "
                ></b-form-select>
              </td>
              <td aria-colindex="6" role="cell" class="">
                <b-form-select  v-if="getFrameworkException() == undefined"
                  v-model="item.goal_id"
                  :options="statusOptions"
                  @change="
                    frameworkStatusChange(item.goal_id, 'goal_id', item.id)
                  "
                ></b-form-select>
              </td>
              <td aria-colindex="7" role="cell" class="statusResponseTd">
                <div
                  class="statusResponserHolder"
                  :style="getRepsonseStatusStyle(item)"
                >
                  <br />
                  <h4>{{ getRepsonseStatus(item) }}</h4>
                </div>
              </td>
            </tr>
            <tr role="row" class="actionbuttonworkAlwayswhite"  v-if="getFrameworkException() == undefined">
                <td colspan="7">

                  <b-tabs content-class="mt-3">
                    <b-tab title="Actions" active>
                      <b-row>
                        <b-col>


                          <Actions :selectedFrameworkItemID="item.id" />

                        </b-col>
                      </b-row>

                    </b-tab>
                    <b-tab title="Tasks">
                          <Tasks :selectedFrameworkItemID="item.id" />

                    </b-tab>
                    <b-tab title="Notes">
                      <b-row>
                        <b-col>

                          <PlansNotes :item="item" v-on:show-notes="showNotes"/>


                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>

                </td>
            </tr>            
          </tbody>

        </table>



        </b-card>
      </div>
      <div class="col-xxl-4" style="overflow:hidden">

        <b-card>
          <div style="width:100px;height:50px;float:left">
                      <button
            type="reset"
            class="btn btn-success mr-2 "
            size="sm"
            @click="$router.go(-1)"
            ref="kt_save_changes"
            
        >
            Back
        </button>   
          </div>
          <div style="width:200px;height:200px;float:right">
            <completed-status v-if="chartvisible" :data="graphData" :title="Goal" />
          </div>          
          <h1 style="clear:both">{{ getFrameworkCategory($route.params.id) }}</h1>
          <table style="width:100%">
            <tr>
              <td style="width:20%">Framework</td>
              <td>:</td>
              <td style="width:30%">
                <b><a href="#/workplans">{{ getName() }}</a></b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Function</td>
              <td>:</td>
              <td>
                <b>{{ getFrameworkValue($route.params.id, "function") }}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Category</td>
              <td>:</td>
              <td>
                <b>{{ getFrameworkValue($route.params.id, "category") }}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Objective</td>
              <td>:</td>
              <td>
                <b>{{ getFrameworkValue($route.params.id, "objective") }}</b>
              </td>
              <td></td>
            </tr>
          </table>


          <h4>Notes</h4>
          <div> 
          <p v-bind:class="[!showMore ? 'manageShowMore' : 'manageShowLess']"
            v-html="formatNotes(getFrameworkValue($route.params.id, 'guidance'))">
          </p>
          </div>
          <a href="" @click.prevent="showMore=!showMore">{{!showMore ? 'Show more' : 'Show less'}}</a>

          <div v-if="getThreatData.length > 0" >
            <h3>Known threats</h3>
            <b-table
              striped
              hover
              sort-icon-left          
              :items="getThreatData"
              :fields="threatFields"
              @row-clicked="rowClickHandlerThreat"
            >
              <template #cell(risk)="data">
                <div :class="riskclass(data.item.risk)">{{data.item.risk}}</div>
              </template>
              <template #cell(systems)="data">
                {{showsystems(data.item.systems)}}
              </template>
            </b-table>               
          </div>


        </b-card>
      </div>
    </div>
    <b-modal
      id="framework-notes"
      ref="modal"
      :title="frameworkNotes.title"
      size="xl"
      @ok="showNotesHandleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
          <vue-editor v-model="frameworkNotes.notes" />
      </form>
    </b-modal>

    <b-modal
      id="framework-exception"
      ref="modal"
      :title="frameworkExceptions.title"
      size="xl"
      @ok="showExceptionHandleOk"
    >

        <ExceptionInformation
          :record="editFRAMEWORKEXCEPTIONRecord"
          :frameworkItems="frameworkItems"
          :selectedFrameworkItemID="frameworkExceptions.id"          
          @canceled="editFRAMEWORKEXCEPTION = false"
        />
    </b-modal>



    <b-modal
      id="framework-exemption"
      ref="modal"
      :title="frameworkExemptions.title"
      size="xl"
      @ok="showExemptionHandleOk"
    >

        <ExemptionInformation
          :record="editFRAMEWORKEXEMPTIONRecord"
          :frameworkItems="frameworkItems"
          :selectedFrameworkItemID="frameworkExceptions.id"          
          @canceled="editFRAMEWORKEXEMPTION = false"
        />
    </b-modal>    


  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_FRAMEWORK,
  UPDATE_FRAMEWORK,
  UPDATE_FRAMEWORK_NOTES,
  GET_DASHBOARD_STATS_PERC
} from "@/core/services/store/framework.module";

import { GET_THREATS } from "@/core/services/store/threats.module";

import { GET_USERS } from "@/core/services/store/users.module";

import {
  CREATE_FRAMEWORKEXCEPTION_DETAILS,UPDATE_FRAMEWORKEXCEPTION_DETAILS
}  from "@/core/services/store/frameworkexceptions.module";

import {
  CREATE_FRAMEWORKEXEMPTION_DETAILS,UPDATE_FRAMEWORKEXEMPTION_DETAILS
}  from "@/core/services/store/frameworkexemptions.module";

import PlansNotes from "@/view/pages/work/plans/components/PlansNotes";
import Actions from "@/view/pages/work/plans/components/Actions";
import Tasks from "@/view/pages/work/plans/components/Tasks";

import ExceptionInformation from "@/view/pages/work/exceptions/ExceptionInformation";
import ExemptionInformation from "@/view/pages/work/exemptions/ExemptionInformation";

import CompletedStatus from "@/view/content/dashboard/CompletedStatus.vue";

import GraphCalcs from "@/core/services/helpers/graph.calcs";


export default {
  name: "workplans",
  components: {
    ExceptionInformation,
    ExemptionInformation,
    CompletedStatus,
    PlansNotes,
    Actions,
    Tasks
  },  
  data() {
    return {

      frameworkNotes: {
        title: 'Requirement Notes - ',
        notes : '',
        state: null,
        framework : '',
        framework_id: ''
      },

      frameworkExceptions: {
        title: 'Requirement Exception - ',
        state: null,
        framework : '',
        framework_id: '',
        framework_requirement_response_id: '',
      },

      frameworkExemptions: {
        title: 'Requirement Exception - ',
        state: null,
        framework : '',
        framework_id: '',
        framework_requirement_response_id: '',
      },


      editFRAMEWORKEXCEPTIONRecord: {
        id: -1,
        reference_id: "",
        description: "",
        system: "All",
        reason: "",
        exception_date: "",
        review_date: "",
        approved_by: "",
      },


      editFRAMEWORKEXEMPTIONRecord: {
        id: -1,
        reference_id: "",
        description: "",
        system: "All",
        reason: "",
        exception_date: "",
        review_date: "",
        approved_by: "",
      },

      notesToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],


      chartvisible: false,
      graphData: { "values": [ 0, 0 ], "colors": [ "#ffffff", "#ffffff" ], "titles": [ "Goal ", "Current" ] },

      showMore:false,

      threatFields: [
        { key: "reference" , sortable: true},
        { key: "name", label: "Name", sortable: true },
        { key: "risk" , sortable: true },
        { key: "systems" , sortable: true },
      ],      

    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "WorkPlan" },
      { title: "Manage" },
    ]);
    this.$store.dispatch(GET_FRAMEWORK);

    this.showMore = false;


    this.$store.dispatch(GET_USERS);       

    this.$store.dispatch(GET_THREATS);

    setTimeout(() => {
      this.$nextTick(() => {
        this.graphData = this.getFrameworkGraphData(this.getFrameworkValue(this.$route.params.id, 'objective_code'));   
        this.chartvisible = true;
      });
    }, 1000);
  },
  methods: {
    getData() {
      var frameworkID = '';
      if (this.currentFramework.framework) {
        frameworkID = this.currentFramework.framework.id;
      }

      this.$store.dispatch(GET_FRAMEWORK,frameworkID);
      this.$store.dispatch(GET_DASHBOARD_STATS_PERC,frameworkID);      
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */

    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    riskclass(value) {
        if (value == 0 ) { return 'transparentrisk' }      
        if (value <= 3 ) { return 'greenrisk' }
        if (value <= 7 ) { return 'yellowrisk' }
        if (value <= 10 ) { return 'orangerisk' }
        if (value <= 25 ) { return 'redrisk' }
        return '';
    },   
    showsystems(values) {
      var retVal = '';
      if (values) {
        values.forEach(value => {
          if (retVal == '' ) { retVal = value.name } else { retVal = retVal + ', ' + value.name}
          
        });
      }
      if (retVal == '') { retVal = '-'}
      return retVal;
    },    
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getSelectedFramework(id) {
      if (this.$route && this.currentFramework.framework_items) {

        return this.currentFramework.framework_items.filter(function(o) {
                  console.log(o.sub_category_code,o)
          return o.id == id;
        });
      } else {
        return [];
      }
    },
    getFrameworkValue(id, value) {
      var result = "";
      var frameworkItem = this.getSelectedFramework(id);
      console.log('frameitem',frameworkItem);
      if (frameworkItem[0]) {
        if (value == "function") {
          result = frameworkItem[0].function;
        }
        if (value == "category") {
          result = frameworkItem[0].category;
        }
        if (value == "objective") {
          result = frameworkItem[0].objective;
        }
        if (value == "guidance") {
          result = frameworkItem[0].guidance;
        }
        if (value == "objective_code") {
          result = frameworkItem[0].objective_code;
        }
      }
      return result;
    },


    getFrameworkException() {
      var result = undefined;
      if (this.currentFramework.framework_exceptions) {
        this.currentFramework.framework_exceptions.forEach((framework_exception) => {
          console.log('framework_exception',framework_exception)
          if (framework_exception.framework_item_id == this.$route.params.id ) {
            result = framework_exception;
          }
        });
      }
      return result;
    },


    getFrameworkExemption(id) {
      var result = undefined;
      if (this.currentFramework.framework_exemptions) {
        this.currentFramework.framework_exemptions.forEach((framework_exemption) => {
          if (framework_exemption.framework_requirement_response_id == id ) {
            result = framework_exemption;
          }
        });
      }
      return result;
    },


    getFrameworkObjectives(code) {
      var results = [];
      if (this.currentFramework.objective_items) {
        this.currentFramework.objective_items.forEach((objective_item) => {
          if (objective_item.objective_code == code) {
            results.push(objective_item);
          }
        });
      }
      return results;
    },


    getFrameworkGraphData(code) {

      return GraphCalcs.getFrameworkGraphData(code,this.currentFramework)

    },

    getRequirementResponse(code) {
      var results = [];
      if (this.currentFramework.requirment_responses) {
        this.currentFramework.requirment_responses.forEach(
          (requirement_response) => {
            if (requirement_response.framework_item_id == code) {
              results.push(requirement_response);
            }
          }
        );
      }
      return results[0];
    },

    getRepsonseStatus(code) {
      var result = "N/A";
      if (this.getFrameworkException(code.id) != undefined) {
        result = "Not Applicable";
      } else {
        if (this.currentFramework.statuses) {
          var targetListResult = this.currentFramework.statuses.filter(function(
            o
          ) {
            return o.id == code.status_id;
          });
          if (targetListResult.length > 0) {
            result = targetListResult[0].name;
          }
        }

      }
      return result;
    },

    getRepsonseStatusStyle(code) {
      var result = "";
      if (this.getFrameworkException(code.id) != undefined) {
        result =
          "background-color:#68BC00;color:#000000";        
      } else {      
        if (this.currentFramework.statuses) {
          var targetListResult = this.currentFramework.statuses.filter(function(
            o
          ) {
            return o.id == code.status_id;
          });
          if (targetListResult.length > 0) {
            result =
              "background-color:" +
              targetListResult[0].bgcolor +
              ";color:" +
              targetListResult[0].color;
          }
        }
      }
      return result;
    },

    getFrameworkCategory(id) {
      var results = "n/a";
      if (this.currentFramework.framework_items) {
        var targetListResult = this.currentFramework.framework_items.filter(
          function(o) {
            return o.id == id;
          }
        );
        if (targetListResult.length > 0) {
          //var code = targetListResult[0].function_code;
          var code = '';
          var name = "";
/*
          if (targetListResult[0].show_category) {
            code = code + "-" + targetListResult[0].category_code;
            name = name + targetListResult[0].category;
          }
*/
          if (targetListResult[0].show_sub_category) {
            code = code + " " + targetListResult[0].sub_category_code;
            if (name != "") {
              name = name + " - ";
            }
            name = name + targetListResult[0].sub_category;
          }

          results = code + ":" + name;
        }
      }
      return results;
    },
    frameworkStatusChange(e, field, id) {
      this.chartvisible = false;
      var response = this.getRequirementResponse(id);
      if (field == "status_id") {
        response.status_id = e;
      }
      if (field == "goal_id") {
        response.goal_id = e;
      }
      if (field == "responsibility_id") {
        response.responsibility_id = e;
      }

      this.$store.dispatch(UPDATE_FRAMEWORK, response);
      this.getData();
    },

    showNotes(item) {
      this.frameworkNotes.title = "Requirement notes : " + item.objective_code + "-" +item.questions;
      this.frameworkNotes.notes = item.notes;
      this.frameworkNotes.id = item.id;
      this.$bvModal.show('framework-notes');
    },

    formatNotes(notes) {
      return notes.replace(/(\r\n|\n|\r)/g,"<br />");
    },

    addException(item) {

      this.editFRAMEWORKEXCEPTIONRecord = {
        id: -1,
        reference_id: "EXCEPTION:" + item.objective_code,
        description: "",
        system: "All",
        reason: "",
        exception_date: new Date().toISOString().slice(0, 10),
        review_date: new Date().toISOString().slice(0, 10),
        approved_by: "",
      };


      if (this.getFrameworkException(item.id) != undefined ) {
        this.editFRAMEWORKEXCEPTIONRecord = this.getFrameworkException(item.id);
      }

      this.frameworkExceptions.title = "Requirement Not Applicable: " + item.objective_code + "-" +item.questions;
      this.frameworkExceptions.framework_requirement_response_id = item.id;
      this.frameworkExceptions.framework_id = item.framework_id;
      this.frameworkExceptions.framework_item_id = this.$route.params.id;
      console.log('EXCEPTION',this.frameworkExemptions,item);
      this.$bvModal.show('framework-exception');
    },


    addExemption(item) {

      this.editFRAMEWORKEXEMPTIONRecord = {
        id: -1,
        reference_id: "EXEMPTION:" + item.objective_code,
        description: "",
        system: "All",
        reason: "",
        exception_date: new Date().toISOString().slice(0, 10),
        review_date: new Date().toISOString().slice(0, 10),
        approved_by: "",
      };


      if (this.getFrameworkExemption(item.id) != undefined ) {
        this.editFRAMEWORKEXEMPTIONRecord = this.getFrameworkExemption(item.id);
      }

      this.frameworkExemptions.title = "Requirement Exemption : " + item.objective_code + "-" +item.questions;
      this.frameworkExemptions.framework_requirement_response_id = item.id;
      this.frameworkExemptions.framework_id = item.framework_id;
      this.frameworkExemptions.framework_item_id = this.$route.params.id;

      console.log('exemption',this.frameworkExemptions);

      this.$bvModal.show('framework-exemption');
    },


    showNotesHandleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.showNotesHandleSubmit()
    },
    showExceptionHandleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.showExceptionHandleSubmit()
    },
    showExemptionHandleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.showExemptionHandleSubmit()
    },

    showNotesHandleSubmit() {
        this.$store.dispatch(UPDATE_FRAMEWORK_NOTES, this.frameworkNotes);
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('framework-notes');
          this.$store.dispatch(GET_FRAMEWORK);
        })
    },


    showExceptionHandleSubmit() {

        console.log(this.frameworkExceptions)
        var validform = true;
        if (this.editFRAMEWORKEXCEPTIONRecord.reference_id == '') { validform = false; }
        if (this.editFRAMEWORKEXCEPTIONRecord.reason == '') { validform = false; }
        if (this.editFRAMEWORKEXCEPTIONRecord.description == '') { validform = false; }

        if (validform) {
          console.log(this.editFRAMEWORKEXCEPTIONRecord)

          var id = this.editFRAMEWORKEXCEPTIONRecord.id;
          var reference_id = this.editFRAMEWORKEXCEPTIONRecord.reference_id;
          var description = this.editFRAMEWORKEXCEPTIONRecord.description;
          var system = this.editFRAMEWORKEXCEPTIONRecord.system;
          var reason = this.editFRAMEWORKEXCEPTIONRecord.reason;
          var exception_date = this.editFRAMEWORKEXCEPTIONRecord.exception_date;
          var review_date = this.editFRAMEWORKEXCEPTIONRecord.review_date;
          var approved_by = this.editFRAMEWORKEXCEPTIONRecord.approved_by;
          var framework_requirement_response_id= this.frameworkExceptions.framework_requirement_response_id;
          var framework_id = this.frameworkExceptions.framework_id;
          var framework_item_id = this.frameworkExceptions.framework_item_id;


          console.log(framework_id,this.frameworkExceptions)

          if (id < 0 ) {
            this.$store
              .dispatch(CREATE_FRAMEWORKEXCEPTION_DETAILS, {
                id,
                reference_id,
                description,
                system,
                reason,
                exception_date,
                review_date,
                approved_by,
                framework_requirement_response_id,
                framework_id,
                framework_item_id

              })
          }
          else {
          this.$store
            .dispatch(UPDATE_FRAMEWORKEXCEPTION_DETAILS, {
              id,
              reference_id,
              description,
              system,
              reason,
              exception_date,
              review_date,
              approved_by,
              framework_requirement_response_id,
              framework_id,
              framework_item_id

            })


          }

          console.log('framework-exception');

          this.$nextTick(() => {
            this.getData();
              this.$bvModal.hide('framework-exception');
          });

        }


    },   


    showExemptionHandleSubmit() {

        var validform = true;
        if (this.editFRAMEWORKEXEMPTIONRecord.reference_id == '') { validform = false; }
        if (this.editFRAMEWORKEXEMPTIONRecord.reason == '') { validform = false; }
        if (this.editFRAMEWORKEXEMPTIONRecord.description == '') { validform = false; }

        if (validform) {
          console.log(this.editFRAMEWORKEXEMPTIONRecord)

          var id = this.editFRAMEWORKEXEMPTIONRecord.id;
          var reference_id = this.editFRAMEWORKEXEMPTIONRecord.reference_id;
          var description = this.editFRAMEWORKEXEMPTIONRecord.description;
          var system = this.editFRAMEWORKEXEMPTIONRecord.system;
          var reason = this.editFRAMEWORKEXEMPTIONRecord.reason;
          var exception_date = this.editFRAMEWORKEXEMPTIONRecord.exception_date;
          var review_date = this.editFRAMEWORKEXEMPTIONRecord.review_date;
          var approved_by = this.editFRAMEWORKEXEMPTIONRecord.approved_by;
          var framework_requirement_response_id= this.frameworkExemptions.framework_requirement_response_id;
          var framework_id = this.frameworkExemptions.framework_id;
          var framework_item_id = this.frameworkExemptions.framework_item_id;


          if (id < 0 ) {
            this.$store
              .dispatch(CREATE_FRAMEWORKEXEMPTION_DETAILS, {
                id,
                reference_id,
                description,
                system,
                reason,
                exception_date,
                review_date,
                approved_by,
                framework_requirement_response_id,
                framework_id,
                framework_item_id

              })
          }
          else {
          this.$store
            .dispatch(UPDATE_FRAMEWORKEXEMPTION_DETAILS, {
              id,
              reference_id,
              description,
              system,
              reason,
              exception_date,
              review_date,
              approved_by,
              framework_requirement_response_id,
              framework_id,
              framework_item_id

            })


          }

          this.$nextTick(() => {
            this.getData();
              this.$bvModal.hide('framework-exemption');
          });

        }


    },
    rowClickHandlerThreat() {      
      this.$router.push({ name: "threatsbyID" , params: { framework: this.$route.params.id} });
    },        







  },
  computed: {
    ...mapGetters(["currentFramework","currentThreatsData"]),

    statusOptions() {
      var results = [];
      this.currentFramework.statuses.forEach(status => {
        results.push({
          value: status.id,
          text: status.name
        });
      });
      return results;
    },
    userOptions() {
      var results = [];
      this.currentFramework.users.forEach(user => {
        results.push({
          value: user.id,
          text: user.name
        });
      });
      return results;
    },
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach(framework_item => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function
            });
          }
        });
      }
      return results;
    },
    getFrameworkObjectivesData() {
      var results = [];
      var code= this.getFrameworkValue(this.$route.params.id, 'objective_code');
      if (this.currentFramework.objective_items) {
        this.currentFramework.objective_items.forEach((objective_item) => {
          if (objective_item.objective_code == code) {
            results.push(objective_item);
          }
        });
      }
      return results;
    },
    getThreatData() {
      if (this.currentThreatsData) {
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.frameworks).includes(this.$route.params.id))
        })
      } else {
        return [];
      }
    }


  },
  watch: {
    currentFramework: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);

        this.chartvisible = false;
        this.$nextTick(() => {
          this.graphData = this.getFrameworkGraphData(this.getFrameworkValue(this.$route.params.id, 'objective_code'));        
          this.chartvisible = true;
        });


    },
  },  
};
</script>

<style>
.statusResponserHolder {
  float: left;
  width: 100%;
  min-height: 10vh;
  padding: 0 10px 10px 10px;
  text-align: center;
  vertical-align: middle;
}
.statusResponseTd {
  padding: 0 !important;
  display: flex;
}
.actionbuttonworkmanage {
  padding:3px;
}
.actionbuttonworkright {
  float:right
}
.manageShowLess {
  width:100%;
  height:100%;
  overflow:inherit;
}
.manageShowMore {
  height:100px;
  overflow: hidden;
}

.actionbuttonworkAlwayswhite{
  background-color: white !important;;
}
.actionbuttonworkAlwayswhite:hover{
  background-color: white !important;;
}
  .transparentrisk {
    width:30px;
    height:30px;
    border-radius:15px;
    line-height:30px;
    text-align:center;    
    background: transparent;

  }
  .greenrisk {
    width:30px;
    height:30px;
    border-radius:15px;
    line-height:30px;
    text-align:center;    
    background: #44C14A;
  }
  
  .orangerisk {
    width:30px;
    height:30px;
    border-radius:15px;
    line-height:30px;
    text-align:center;    
      background-color: #FFA500;
  }
  
  .yellowrisk {
    width:30px;
    height:30px;
    border-radius:15px;
    line-height:30px;
    text-align:center;    
      background-color: #FFFF00;
  }
  
  .redrisk {
    width:30px;
    height:30px;
    border-radius:15px;
    line-height:30px;
    text-align:center;    
      background-color: #BB413E;
      color:white;
  }

</style>

