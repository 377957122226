<template>
  <div>
        <h4>Tasks</h4>
        <div v-if="currentFRAMEWORKTASKsDataItems.length==0">
            <b> No tasks set yet </b>
        </div>

        <div v-if="currentFRAMEWORKTASKsDataItems.length>0">
        <b-table
            striped
            hover
            sort-icon-left        
            :items="currentFRAMEWORKTASKsDataItems"
            :fields="frameworktaskFields"
            @row-clicked="rowClickHandler"
        >
            <template #cell(framework_requirement_response_id)="data">
            {{getFrameworkLinkedTo(data.item)}}
            </template>
            <template #cell(tasks)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id)"
                >mdi-delete</v-icon
            >
            </template>
        </b-table>
        </div>

        <button
            type="reset"
            class="btn btn-success mr-2 taskbuttonworkmanage"
            size="sm"
            @click="create()"
            ref="kt_save_changes"
            
        >
            Add New TASK
        </button>        

        <b-modal
        :id="'edit-task-modal' + selectedFrameworkItemID"
        ref="modal"
        size="xl"
        hide-footer
        hide-header
        @ok="showExceptionHandleOk"
        >

            <TasksInformation
            :record="editFRAMEWORKTASKRecord"
            :frameworkItems="frameworkItems"
            :selectedFrameworkItemID="selectedFrameworkItemID"
            @canceled="canceled()"
            @finished="finished()"
            />
        </b-modal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_FRAMEWORKTASKS,
  DELETE_FRAMEWORKTASK_DETAILS,
} from "@/core/services/store/frameworktasks.module";

import TasksInformation from "@/view/pages/work/tasks/TasksInformation";

export default {
  name: "manageframeworktasks",
  components: {
    TasksInformation,
  },
  props: {
    selectedFrameworkItemID: String,
    
  },  
  data() {
    return {
      editFRAMEWORKTASK: false,
      editFRAMEWORKTASKRecord: [],
      selectedFrameworkID: "",

      frameworktaskFields: [
        { key: "reference_id", label: "Reference", sortable: true },
        { key: "framework_requirement_response_id", label: "Linked To" },
        { key: "name" , label: "Task"},
        { key: "priority" },
        { key: "tasks", label: "" },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_FRAMEWORKTASKS, {framework_id: this.selectedFramework});
    },
    getFrameworkLinkedTo(item) {
      var retVal = 'n/a';
      if (item.framework_requirement_response_id == null) { 
        retVal = "All";
      } else {
        if (this.currentFramework.objective_items) { 
          this.currentFramework.objective_items.forEach(o => {
            if (o.id == item.framework_requirement_response_id) {
            console.log('------',o,item.framework_requirement_response_id);
                retVal = o.objective_code + ' - ' + o.objective + ' - ' + o.questions;
            }
            
          });
        }
      }        
      
      return retVal;
    },
    finished() {
      console.log('finsihed');
      this.editFRAMEWORKTASK = false;
      this.editFRAMEWORKTASKRecord = [];
      this.$bvModal.hide('edit-task-modal' +  this.selectedFrameworkItemID);      
      setTimeout(() => {
        this.getData();
      }, 500);

    },
    canceled() {
      this.editFRAMEWORKTASK = false;
      this.editFRAMEWORKTASKRecord = [];
      this.$bvModal.hide('edit-task-modal' +  this.selectedFrameworkItemID);      
      setTimeout(() => {
        this.getData();
      }, 500);

    },
    rowClickHandler(record, index) {
        console.log(record, index); // This will be the item data for the row
        this.editFRAMEWORKTASK = true;
        this.editFRAMEWORKTASKRecord = record;
        this.$bvModal.show('edit-task-modal' +  this.selectedFrameworkItemID);
    },

    create() {

     var taskReference =  String('00000' + this.currentFRAMEWORKTASKsData.length).slice(-4);        
      this.editFRAMEWORKTASK = true;
      this.editFRAMEWORKTASKRecord = {
        id: -1,
        reference_id: "TS:" + taskReference,
        task: "",
        status: "",
        how: "",
        when_date: "",
        who_id: "",
        validation: "",
        review_date: ""
      };
        this.$bvModal.show('edit-task-modal' +  this.selectedFrameworkItemID);      
    },
    deleteRecord(id) {
      console.log("delete");
      if (confirm("Do you really want to delete?")) {
        this.editFRAMEWORKTASK = false;
        this.$store.dispatch(DELETE_FRAMEWORKTASK_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);

      }
    },
  },
  computed: {
    ...mapGetters(["currentFRAMEWORKTASKsData","selectedFramework","currentFramework"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    currentFRAMEWORKTASKsDataItems() {

      var targetListResult = this.currentFRAMEWORKTASKsData;

            console.log('this.selectedFrameworkItemID');
            console.log(this.selectedFrameworkItemID);
  
      if (this.currentFRAMEWORKTASKsData.length > 0) {
        if (this.selectedFrameworkItemID != '') {
          targetListResult = [];
          this.currentFRAMEWORKTASKsData.forEach(o => {
            if (o.framework_requirement_response_id == this.selectedFrameworkItemID) {
              targetListResult.push(o);
            }
            
          });
        }
      }
        return targetListResult;
    },
    frameworkItems() {
      var results = [];
      results.push({
        value: '',
        text: 'All',
      });
      
      if (this.currentFramework.objective_items) {   
        this.currentFramework.objective_items.forEach((item) => {
          results.push({
            value: item.id,
            text: item.objective_code + ' - ' + item.objective + ' - ' + item.questions,
          });
        });
      }
      return results;
    },

    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
  },
};
</script>
