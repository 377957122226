<template>
    <div  style="width:100%;">
        <h4>{{item.notes ? 'Notes' : 'No Notes'}}</h4>
        <div v-if="item.notes"  v-bind:class="[!notesExpanded ? 'manageShowMore' : 'manageShowLess']">
        <div v-if="item.notes" v-html="item.notes"></div>
        
        </div>
        <a href="" @click.prevent="notesExpanded=!notesExpanded">{{!notesExpanded ? 'Show more' : 'Show less'}}</a>
        <b-button  variant="info" size="sm" class="actionbuttonworkmanage actionbuttonworkright" @click="showNotes(item)">Edit Notes</b-button>
    </div>
</template>

<script>

export default {
  name: "plans-notes",
  props: {
    item: Object
  },
  data() {
    return {
        notesExpanded:false
    };
  },
  methods: {
      showNotes(item) {
        this.$emit(
            "show-notes",
            item
        );
      }
  }
};
</script>
