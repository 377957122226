export const getFrameworkGraphData = (code,currentFramework) => {

    console.log(code,currentFramework);
 
    var results = {
        values:[],
        colors:[],
        titles:['Goal  ','Current']
    };
  
    var goalStatus = [];
    var statusStatus = [];
  
    if (currentFramework.objective_items) {
        currentFramework.objective_items.forEach((objective_item) => {
        if (objective_item.objective_code == code) {

            goalStatus.push(...currentFramework.statuses.filter(function(o) {return o.id == objective_item.goal_id;}));
            statusStatus.push(...currentFramework.statuses.filter(function(o) {return o.id == objective_item.status_id;}));

        }
        });
    }

    var max = 0;
    for (let i = 0; i < goalStatus.length; i++) {
        max = max + goalStatus[i].score;
    }
    if (max > 0) { results.values.push(parseInt(max/goalStatus.length)); } else { results.values.push(0);}

    max = 0;
    for (let i = 0; i < statusStatus.length; i++) {
        max = max + statusStatus[i].score;
    }
    if (max > 0) { results.values.push(parseInt(max/statusStatus.length)); } else { results.values.push(0);}


    var sortedstatus = currentFramework.statuses.sort((a, b) => (a.score < b.score) ? 1 : -1);

    var goalcolor = '';
    var statuscolor = '';
    sortedstatus.forEach(s => {
        if (s.score <= results.values[0] && goalcolor == '') { goalcolor = s.bgcolor}
        if (s.score <= results.values[1] && statuscolor == '') { statuscolor = s.bgcolor}
    });

    results.colors=[goalcolor,statuscolor];
    

    return results;

};
  
export default { getFrameworkGraphData };
  