<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Reference</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="reference_id"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.exceptionRecord.reference_id.$model"
            />
            <div class="error" v-if="!$v.exceptionRecord.reference_id.required">
              <i>Task Reference is required</i>
            </div>
          </div>
        </div>
     

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Exception Description</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-textarea
              ref="description"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="exceptionRecord.description"
            />
            <div class="error" v-if="!$v.exceptionRecord.description.required">
              <i>Exception is required</i>
            </div>            
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >System</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="system"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="exceptionRecord.system"
            />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Date</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="exception_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="exceptionRecord.exception_date"
            />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Approved by</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                v-model="exceptionRecord.approved_by"
                :options="getUserData"
            ></b-form-select>            
            <!-- <b-input
              ref="approved_by"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="exceptionRecord.approved_by"
            /> -->
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Reason</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-textarea
              ref="reason"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="exceptionRecord.reason"
            />
            <div class="error" v-if="!$v.exceptionRecord.reason.required">
              <i>Reason is required</i>
            </div>            
          </div>
        </div>



        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Review Date</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="review_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="exceptionRecord.review_date"
            />
          </div>
        </div>   


      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  GET_USERS,
} from "@/core/services/store/users.module";


export default {
  components: {},
  mixins: [validationMixin],
  name: "ExceptionInformation",
  props: {
    record: Object,
    selectedFrameworkItemID: String,
    frameworkItems:Array,    
  },
  data() {
    return {
      exceptionRecord: this.record,
      selectedFrameworkItemIDSelected: this.selectedFrameworkItemID      
    };
  },
  validations: {
    exceptionRecord: {
      reference_id: {
        required,
      },
      description: {
        required,
      },
      reason: {
        required,
      },
    },
  },
  mounted() {
      this.$store.dispatch(GET_USERS);    
  },
  methods: {
  },
  computed: {
    ...mapGetters(["currentUser","selectedFramework","currentUsersData"]),
    getUserData() {
      var users = [];
      this.currentUsersData.users.forEach(user => {
        users.push({value:user.id,text:user.name})
        
      });
      return users
    }
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
