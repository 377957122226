<template>
    <div  style="width:300px;">
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="series"
        height="200px"
      ></apexchart>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "completed-status",
  props: {
    data: Object,
  },
  data() {
    return {
      series: this.data.values,

      chartOptions: {
            chart: {
              height: 100,
              width: 100,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                  margin: 5,
                  size: '30%',
                  background: 'transparent',
                  image: undefined,
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  }
                }
              }
            },
            colors: this.data.colors,
            labels: this.data.titles,
            legend: {
              show: true,
              floating: true,
              fontSize: '12px',
              position: 'left',
              offsetX: 0,
              offsetY: 15,
              labels: {
                useSeriesColors: true,
              },
              markers: {
                size: 0
              },
              formatter: function(seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
              },
              itemMargin: {
                vertical: 3
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                    show: false
                }
              }
            }]
          },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {},
};
</script>
